import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.constructor.js";
import useBaseUtils from "~/functions/useBaseUtils";
var publicKey = process.env.RECURLY_PUBLIC_KEY;
export default defineComponent({
  props: {
    planid: {
      type: String,
      "default": "a-2022_v0-global"
    },
    intervalInt: {
      type: Number,
      "default": 0
    },
    iso: {
      type: String,
      "default": "USD"
    }
  },
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      dev = _useBaseUtils.dev,
      refCode = _useBaseUtils.refCode,
      idtoken = _useBaseUtils.idtoken;
    var buttonLoading = ref(false);
    return {
      dev: dev,
      refCode: refCode,
      idtoken: idtoken,
      buttonLoading: buttonLoading
    };
  },
  data: function data() {
    return {
      recurly: null,
      recurlyElements: null,
      paypal: null,
      loadingPaypal: true
    };
  },
  computed: {
    tierId: function tierId() {
      var plan = this.planid.toLowerCase();
      var intervalId = this.intervalInt;
      var periodLetter = intervalId ? "a" : "m";
      return "".concat(periodLetter, "-").concat(plan);
    }
  },
  mounted: function mounted() {
    var linkEl = document.createElement("link");
    linkEl.href = "https://js.recurly.com/v4/recurly.css";
    linkEl.rel = "stylesheet";
    linkEl.type = "text/css";
    document.body.appendChild(linkEl);
    var script = document.createElement("script");
    script.src = "https://js.recurly.com/v4/recurly.js";
    script.addEventListener("load", this.scriptLoaded);
    document.body.appendChild(script);
  },
  methods: {
    loadPaypal: function loadPaypal() {
      var that = this;
      that.loadingPaypal = true;
      var paypal = window.recurly.PayPal({
        display: {
          displayName: "TIKR Test"
        },
        braintree: {
          clientAuthorization: "sandbox_6m5ynmhm_mp6f8xbdgcdvhgqp"
        }
      });
      paypal.on("ready", function () {
        that.loadingPaypal = false;
      });
      paypal.on("error", function (err) {
        console.log("paypal error:", err);
      });
      paypal.on("token", function (token) {
        console.log("paypal token: ", token);
      });
      this.paypal = paypal; // paypal.start() initiates flow for paypal popup
    },
    scriptLoaded: function scriptLoaded() {
      // const recurly = window.recurly.configure(publicKey)
      window.recurly.configure(publicKey);
      var elements = window.recurly.Elements();
      this.recurlyElements = elements;
      var cardElement = elements.CardElement();
      cardElement.attach("#recurly-elements");
      this.loadPaypal();
    },
    subscribe: function subscribe() {
      this.$refs.payform.validate();
      var elements = this.recurlyElements;
      var form = document.querySelector("#payment-form");
      window.recurly.token(elements, form, function (err, token) {
        console.log("recurly.token token: ", token);
        if (err) {
          console.error("error: ", err);
        } else {
          form.submit();
        }
      });
    },
    subscribeWithPaypal: function subscribeWithPaypal() {
      this.paypal.start();
    }
  }
});